<template>
  <section class="m-aftersale-logistics m-scroll">
 
    <!-- 头部导航 -->
    <m-header title="填写发货单">
      <span @click="onSave" slot="right">保存</span>
    </m-header>



    <!-- 商品信息 -->
    <div class="title">
      <span>*</span>
      <span>包裹信息</span>
    </div>
    
    <div class="info m-padding">

      <div class="info-cell">
        <span>快递公司</span>
        <input v-model="company" type="text" placeholder="请输入">
      </div>
      <div class="info-cell">
        <span>快递单号</span>
        <input v-model="number" type="text" placeholder="请输入" style="border:none">
      </div>

    </div>


    <div class="title">
      <span>*</span>
      <span>包裹内商品信息</span>
    </div>
    <ul class="goods-list m-padding">
      <li class="goods-item" v-for="i in 1" :key="i">
        <router-link to="/zh/goods/detail/1">
          <img src="@/assets/fork-imgs/1591779685230830jvb.jpg" alt="goods">
          <div>
            <p class="van-ellipsis">迷你萌熊加湿器小型喷雾 真实的好用真的好谁用谁知道</p>
            <p>数量：2</p>
            <p>$42.82</p>
          </div>
        </router-link>
      </li>
    </ul>


    <div class="footer">
        <van-button @click="onSave"  block round color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">保存</van-button>
      </div>  
    





  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'


export default {
  name:'AftersaleLogistics',
  components:{ MHeader },
  data(){
    return {
      company: '',
      number:  '',
    }
  },

  methods:{
    onSave(){
      const that = this 
      that.$notify({
        type: 'success',
        message: '保存成功',
        onOpened(){
          that.$router.go(-1)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.m-aftersale-logistics {
  width: 100%;
  height: 100%;
  padding-top: 44px;
  background-color: #F5F9FF;
  overflow-x: hidden;
  overflow-y: auto;
  .title {
   height: 40px;
   padding: 0 16px;
   display: flex;
   align-items: center;
   font-size:14px;
    
    font-weight:400;
    color:#333;
    line-height:20px;
    >span:first-of-type {
      color: #ED2A24;
    }
  }
  .goods-list {
    background: #fff;
    position: relative;
    z-index: 12;
    padding: 12px 16px;
    .goods-title {
      height: 44px;
      line-height: 44px;
      >a {
        font-size: 14px;
        font-weight: 500;
        color: #333;
      }
    }
    .goods-item {
      margin-bottom: 12px;
      &>a {
        display: flex;
      }
      &>a>img {
        width: 60px;
        height: 60px;
        border-radius: 2px;
        margin-right: 10px;
      }
      &>a>div {
        flex: 1;
        width: 0;
        &>p:nth-of-type(1) {
          font-size:13px;
          font-weight:400;
          color:rgba(51,51,51,1);
          line-height:18px;
        }
        &>p:nth-of-type(2) {
          margin-top: 2px;
          font-size:12px;
          font-weight:400;
          color:#666;
          line-height:17px;
        }
        &>p:nth-of-type(3) {
          margin-top: 5px;
          font-size:13px;
          font-weight:500;
          color:rgba(51,51,51,1);
          line-height:18px;
        }
      }
    }
  }
   .info {
    background-color: #fff;
    z-index: 12;
    .info-cell {
      height: 44px;
      display: flex;
      width: 100%;
      margin-right: 6px;
      &>span {
        width: 75px;
        height: 44px;
        line-height: 44px;
        font-size: 13px;
        color: #666;
        font-weight: 400;
      }
      &>input {
        flex: 1;
        width: 0;
        font-size: 13px;
        height: 44px;
        line-height: 26px;
        color: #666;
        font-weight: 500;
        letter-spacing: 1px;
        outline: none;
        border: none;
        appearance: none; 
        border-bottom: 1px solid #f0f0f0;
      }
      
    }
    .info-cell-line {
      margin: 8px 0 12px;
      width: 271px;
      height: 1px;
      background: #D9D9D9;
      margin-left: 75px;
    }
  }
  .footer {
    margin: 20px 38px 0;
  }
}
</style>